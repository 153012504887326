export const apiConst = {
  encdef: 'vote for NDM',
  bucdef: 'prod-akna-corporate-policy-docs',
  bucpol: 'prod-akna-member-uploaded-policies',
  conpho: 'prod-akna-consumer-photographs',
  bandef: 'prod-akna-banner-bucket',
  clidef: 'akna',
  cladoc: 'prod-akna-claim-docs',
  idtdoc: 'prod-akna-tempidcard',
};
